@import '../../../../../common/styles/styles';

.container{
  display: flex;
  justify-content: space-between;
  width: 33%;

  .label-n-value-container{

    .label{
      color: $horizon-grey;
      letter-spacing: 1px;
      font-size: 0.9rem;
    }
  
    .value{
      margin: 0.5rem 0;
      font-size: 1.3rem;
      font-weight: 600;
      color: $horizon-dark-blue;
    }
  }

  .v-line{
    border: 0;
    background-color: #dbdbdb;
    width: 1px;
  }

}