@import '../../common/styles/styles';

.communications{

    .create-new-container{
        display: flex;
        justify-content: right;
        margin-bottom: 2rem;
        gap: 1rem;
        margin-top: -1.7rem;
        align-items: center;

        .create-comms-btn {
            color: #fff;
            background-color: $horizon-orange;
        }

        .refresh-comms-btn {
            margin-right: 0.5rem;
            color: #fff;
            background-color: #000;
        }
    }
}