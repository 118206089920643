@import '../../../../../../common/styles/styles';

.popover-container{
    position: absolute;
    width: 15rem;
    z-index: 50;

    .menu-body {
        max-height: 10rem;
        overflow: auto;
        border: 1px solid #ddd;
        z-index: 1;
        margin-top: 0.2rem;
        padding-bottom: 0.2rem;
        display: flex;
        flex-direction: column;
        border-radius: 0.25rem;
        box-shadow: 2px 2px 7px grey;
        background-color: #ffffff;

        .selected-items{
            margin: 0rem auto;
            width: 90%;
            font-size: small;
            word-break: break-word;
        }

        .search-input{
            @include inputStyles();
            width: 85%;
            margin: 0.5rem auto;

        }
        
        .ul{
            list-style-type: none;
            margin: 0.5rem auto;
            width: 90%;

            .li{
                cursor: pointer;
                display: flex;
                align-items: center;
                font-size: small;
                gap: 0.5rem;
                
                label{
                    cursor: pointer;
                }
            }
        }
    }
}