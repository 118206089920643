@import "../../../common/styles/styles";
@import "../adhocCommunicationModal";

@mixin Input-n-Button {
  display: flex;
  gap: 0.5rem;

  input {
    @include inputStyles();
    // width: 90%;
    // height: 10rem !important
  }

  Button {
    color: #fff;
    font-size: 0.8rem;
    background-color: $horizon-turquoise;
    box-shadow: $action-btn-box-shadow;

    &:disabled {
      color: #e0dddd;
      background-color: #98c0ba;
    }
  }
}

.smsModal-container {
  .header {
    @include headerStyles();
  }

  .sub-header {
    @include subHeaderStyles();
  }

  .main {
    height: fit-content;
    position: relative;
    left: -0.85rem;
    width: 101.4%;
    margin-top: 1rem;
    padding: 0.5rem;
    background-color: $horizon-grey-6;

    .audience-selection-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;

      .message-name-input {
        @include inputStyles();
        padding: 0.4rem 0rem;
        padding-left: 0.5rem;
        width: 98.6%;
      }
    }

    .textarea-n-select {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      gap: 1rem;

      .textarea-container {
        width: 50%;

        .textarea {
          width: 100%;
          resize: none;
          height: 10rem;
          border: $horizon-dark-border;
          border-radius: 0.25rem;
          font-family: $hoizon-font-family;
          padding-left: 0.5rem;

          &::placeholder {
            font-size: 0.875rem;
            color: $horizon-dark-blue;
          }
        }
        .button {
          color: #fff;
          font-size: 0.8rem;
          background-color: $horizon-turquoise;
          box-shadow: $action-btn-box-shadow;

          &:disabled {
            color: #e0dddd;
            background-color: #98c0ba;
          }
        }
      }

      .select-container {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        width: 50%;

        .selected-fields-list-title {
          font-size: 0.7rem;
        }

        .selected-field-items-container {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: 0.5rem;
        }

        .configure-fullback-btn-container {
          position: relative;

          .fall-back-btn {
            text-align: center;
            color: #fff;
            font-size: 0.8rem;
            background-color: $horizon-turquoise;
            box-shadow: $action-btn-box-shadow;
            text-transform: capitalize;

            &:disabled {
              color: #e0dddd;
              background-color: #98c0ba;
            }
          }
        }
      }
    }

    .gsm-limit {
      width: 50%;
      font-size: 0.8rem;
      text-align: center;
      margin-bottom: 0.5rem;
    }

    .bitly-input-container {
      @include Input-n-Button();
    }

    .bitly-error {
      color: red;
      font-size: 0.6rem;
    }

    .test-section {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .schedule {
        width: 100%;

        input {
          @include inputStyles();
          width: 98%;
        }
      }

      .input-n-button {
        @include Input-n-Button();
      }
    }
  }

  .actions {
    @include actionButtonStyles();
  }
}

@media screen and (max-width: 102rem) {
  .smsModal-container {
    .main {
      width: 102.4%;
    }
  }
}
