// .details-container{
//   min-width: 40rem;
//   display: flex;
//   gap: 2.5rem;
//   margin-bottom: 1.5rem;
  
//   .table{

//     .tbody{

//       tr td{

//         &:nth-child(1) {
//           font-style: italic;
//         }
//       }
//     }
//   }
// }
@import '../../../../common/styles/styles';

@mixin lineStyling {
  border: 0;
  height: 0.5px;
  background-color: #dbdbdb;
}

.details-container{
  margin-bottom: 1rem;
  
  .line {
    @include lineStyling();
    margin-top: 0.5rem;
  }

  .metrics-container{

    .row {
      display: flex;
      gap: 1rem;
      margin: 1rem 0;
    }
  }
}