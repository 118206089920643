@import '../../../../../../common/styles/styles';

.container {
    .search-input-container {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        display: flex;

        .search-input{
            @include selectInputStyles-2();
            width: 100%;
            margin-right: 1rem;
        }
    }


    .search-items-container {
        border: 1px solid #0000003b;
        border-radius: 0.25rem;
        font-size: 0.8rem;
        height: 50vh;
        overflow-y: scroll;

        .search-item {
            .search-item-text {
                padding: 0 0.5rem;
            }

            &:hover {
                background-color: blue;
                color: #fff
            }
        }
    }

}