
.container{
    border: 1px solid #bbb;
    min-width: 15rem;
    margin: 1rem 2rem;
    padding: 0.5rem;
    font-size: 0.8rem;
    border-radius: 0.25rem;

    .expand-less-icon{
        float: left;
        margin-left: 0.5rem;
        margin-top: 0.25rem;
        cursor: pointer;
    }

    .expand-more-icon{
        float: left;
        margin: 0.25rem 1rem 1rem 0.5rem;
        cursor: pointer;
    }

    .tab-container{
        display: flex;

        .tab{
            padding: 1rem;
        }
    }

    .overview-container{
        display: flex;
        flex-wrap: wrap;

        .overview{
            width: 13rem;
            white-space: nowrap;
        }
    }
}

@media screen and (max-width: 53rem){
    .container{

        .tab-container{
            flex-direction: column;
        }
    }
}