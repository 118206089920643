@import '../../../../../common/styles/styles';

.rule-container {
    display: flex;
    align-items: center;
    position: relative;
    gap: 1rem;
    margin-bottom: 0.5rem;
    justify-content: space-between;

    .remove-rule-button{
        cursor: pointer;
        padding: 0.15rem;
        color: grey;
        border-radius: 0.25rem;
        border: none;
        background-color: transparent;

        span{
            font-size: 1.5rem;
            line-height: normal;
        }

        &:active{
            color: #dc3545;
        }

        &:hover{
            color: #dc3545;
        }
    }

    .rule-wrapper{
        display: flex;
        align-items: center;
        gap: 0.5rem;

        .field-search-btn {
            margin-left: -0.5rem;
        }   

        .field-search-btn:hover {
            cursor: pointer;
        }
        
        .field-group-text{
            display: flex;
            flex-direction: column;
            font-size: 0.8rem;

            p{
                white-space: nowrap;
            }
        }

        .rule-value-container {
            display: flex;
            flex-direction: column;
            font-size: 0.8rem;
            width: auto;
            min-width: 15rem;

            p {
                white-space: wrap;
            }
        }

        .field-select{
            @include selectInputStyles-2()
        }

        .operator-select{
            @include selectInputStyles-2()
        }

        .input{
            @include selectInputStyles-2()
        }
    }
}


@media screen and (max-width: 55rem){
    .rule-container {
        position: relative;
        padding: 0.25rem;
        border: 0.25rem solid #F3F4F6;

        .rule-wrapper{
            flex-direction: column;
            align-items: flex-start;
        }
    }
}

@media screen and (max-width: 22rem){
    .rule-container {
        position: relative;
        padding: 0.25rem;
        border: 0.25rem solid #F3F4F6;
        flex-direction: column;

        .rule-wrapper{
            flex-direction: column;
            align-items: flex-start;
        }
    }
}