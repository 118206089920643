@import '../../../common/styles/styles';

.menu-item {
    cursor: pointer;
    padding: 0.5rem 0rem;
    text-align: center;
    color: #fff;

    &:hover{
        background-color: grey;
    }

    .icon {
        color: $horizon-grey;
    }
}