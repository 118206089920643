body {
  font-family: 'Arial';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Arial'
}

* {
  margin: 0;
  padding: 0;
}


