@import '../../../common/styles/styles';
@import '../adhocCommunicationModal';

.modal-container {

    .header{
        @include headerStyles()
    }

    .sub-header{
        @include subHeaderStyles()
    }

    .main{
        height: fit-content;
        position: relative;
        left: -0.9rem;
        width: 101.4%;
        margin-top: 1rem;
        padding: 0.5rem;
        background-color: $horizon-grey-6;
    }

    .actions {
        @include actionButtonStyles()
    }
}


@media screen and (max-width: 102rem){
    .modal-container{ 

        .main{
            width: 102%;
        }
    }
}