@import '../../../common/styles/styles';

.wrapper {

    .parent-container{
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem 0rem;
        color: #fff;
        
        .icon {
            padding-right: 2rem;
            color: $horizon-grey;
        }
        
        .text {
            width: 9.5rem;
            font-family: $hoizon-font-family;
            font-size: 1rem;
        }

        .btn-icon{
            font-weight: 600;
            line-height: normal;
        }
    }
    
    .children-container{
        transition: height 0.5s;
        overflow: hidden;
    }
    
    hr {
        @include lineStyles()
    }
}

