@import "../../../common/styles/styles";

@mixin statusTextStyles {
  white-space: nowrap;
  border-radius: 0.5rem;
  padding: 0.1rem 0.25rem;
}

.container {
  .back-btn-container {
    .back-to-communication-btn {
      @include cancelButtonStyles();
      margin-bottom: 1rem;
    }
  }

  .header-container {
    display: flex;
    justify-content: space-between;
    gap: 2rem;

    .header {
      @include headerStyles();
      margin-bottom: 0.75rem;
    }

    .buttons-container {
      display: flex;
      // flex-direction: column;
      align-items: center;
      gap: 1rem;

      .approve-btn {
        @include cancelButtonStyles();
        color: #fff;
        background-color: green;
      }

      .decline-btn {
        @include cancelButtonStyles();
        color: #fff;
        background-color: red;
      }

      .resend-btn {
        @include cancelButtonStyles();
        color: white;
        background-color: $horizon-orange;
      }

      .duplicate-btn {
        @include cancelButtonStyles();
        color: white;
        background-color: $horizon-orange;
      }

      .pending-text {
        @include statusTextStyles();
        border: 1px solid grey;
      }

      .sending-scheduled-text {
        @include statusTextStyles();
        border: 1px solid darkgreen;
        color: green;
      }

      .declined-text {
        @include statusTextStyles();
        border: 1px solid red;
        background-color: red;
        color: #fff;
      }

      .sent-text {
        @include statusTextStyles();
        border: 1px solid darkgreen;
        background-color: green;
        color: #fff;
      }
    }
  }

  .loading-spinner {
    text-align: center;
  }

  .expand-btn-container {
    text-align: center;

    .expand-btn {
      padding: 0.25rem 0.5rem;
      background-color: $horizon-grey-6;
    }
  }

  .table-wrapper {
    margin-top: 0.5rem;
    padding: 0.5rem;

    .members-header {
      @include headerStyles();
      text-align: center;
      margin-bottom: 0.5rem;
      font-size: 1rem;
    }
  }
}
