@import '../../../../common/styles/styles';

.select-modal-container{
    width: 40%;
    margin: 1rem auto;
  
    .select-container{
      
      .label{
        margin-bottom: 0.5rem;
        font-weight: 600;
        color: $horizon-dark-slate;
      }

      .select{
        @include selectInputStyles();
        width: 100%;
      }
    }
  
    .actions{
      float: right;
      margin-top: 1rem;
  
      .save{
        @include saveButtonStyles()
      }
    }
}

@media screen and (max-width: 40rem){
  .select-modal-container{
    width: 70%;
  }
}
  