@import '../../../common/styles/styles';


.selections {
    width: 100%;
    
    .create-new-container{
        display: flex;
        justify-content: right;
        margin-bottom: 2rem;
        margin-top: -2.7rem;
        margin-right: 4rem;

        .create-audience-btn {
            color: #fff;
            background-color: $horizon-orange;
        }

        .refresh-audiences-btn {
            margin-right: 0.5rem;
            color: #fff;
            background-color: #000;
        }
    }
}

  




