@import '../../../common/styles/styles';

.header{
  @include headerStyles()
}

.audience-records {
  width: 100%;
  
  .edit-button-container{
    display: flex;
    justify-content: right;
    margin-bottom: 2rem;
    margin-top: -1.7rem;
    
    Button {
      color: #fff;
      background-color: $horizon-turquoise;
    }
  }
}