$horizon-dark-blue: #232931;
$horizon-dark-slate: #343D49;
$horizon-light-action-active: #0000008a;
$horizon-orange: #ff6d00;
$horizon-grey: #bdbdbd;
$horizon-turquoise: #83C1B9;
$horizon-grey-6: #f2f2f2;
$horizon-dark-border: 1px solid #0000003b;
$action-btn-box-shadow: 0px 3px 1px -2px #00000033, 0px 2px 2px #00000024, 0px 1px 5px #0000001f;
$hoizon-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


@mixin lineStyles {
  border: 0;
  border-top: 1px solid grey;
}

@mixin headerStyles {
  font-family: $hoizon-font-family;
  font-weight: 500;
  font-size: 1.25rem;
  color: $horizon-dark-blue
}

@mixin subHeaderStyles {
  color: $horizon-dark-blue;
  font-size: 0.875rem;
  font-family: $hoizon-font-family;
  letter-spacing: 0.17px;
}

@mixin selectInputStyles {
  border-radius: 0.25rem;
  border: $horizon-dark-border;
  background-color: #fff;
  font-size: 0.875rem;
  width: 100%;
  padding-left: 0.5rem;

  &::before{
    border-bottom: none;
  }
}

@mixin selectInputStyles-2 {
  font-size: 0.8rem;
  border-radius: 0.25rem;
  padding: 0.2rem 0.4rem;
  border: $horizon-dark-border;
  font-family: $hoizon-font-family;
}

@mixin inputStyles {
  padding: 0.4rem 0.5rem;
  height: 1rem;
  width: 100%;
  color: $horizon-dark-blue;
  border: $horizon-dark-border;
  border-radius: 0.25rem;
  
  &::placeholder{
    color: $horizon-dark-blue;
  }
}

@mixin saveButtonStyles {
  color: #fff;
  font-size: 0.8rem;
  padding: 0.25rem 0.75rem;
  background-color: $horizon-turquoise;
  box-shadow: $action-btn-box-shadow;

  &:disabled{
    color: #e0dddd;
    background-color: #98c0ba;
  }
}

@mixin cancelButtonStyles {
  color: $horizon-dark-blue;
  font-size: 0.8rem;
  padding: 0.25rem 0.75rem;
  background-color: #E0E0E0;
  box-shadow: $action-btn-box-shadow;
}

@mixin reactTabsStyles {
  .tab-list{
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  border-bottom: 3px solid #dee2e6;
  font-size: 0.8rem;

  .tab{
    bottom: -3px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
  }

  .selectedTab{
    background-color: #fff;
    color: black;
    border-radius: 5px 5px 0 0;
    border-bottom: 3px solid #525151;
  }
}
}
