@import '../../../../common/styles/styles';

.tr {
    position: relative;

    &:hover {
        background-color: $horizon-grey;
        cursor: pointer;
        color: white;
    }
    
    .td {
        border-top: 1px solid #ddd;
        padding: 8px;
        text-align: center;
    }
}