@import '../../../common/styles/styles';

.audience-builder-container{

    .header{
        @include headerStyles()
    }

    .sub-header{
        @include subHeaderStyles()
    }

    .audience-info-container{
        display: flex;
        justify-content: space-between;
        margin-top: 1.5rem;
        gap: 2rem;

        .audience-info{
            display: flex;
            flex-direction: column;
            gap: 0.9rem;
            width: 60%;

            input {
                @include inputStyles();
            }

            .audience-name-input-container{

                .audience-name-error-text{
                    font-size: 0.7rem;
                    color: red;
                }
            }
        }

        .audience-contacts{
            width: 35%;
            background-color: #dbdbdb;
            border-radius: 0.25rem;
            padding: 0.5rem 1rem;
            

            .wrapper{
                display: flex;
                flex-direction: column;
                align-items:flex-start;
                justify-content: space-between;
                gap: 0.5rem;

                .contacts{
                    font-size: 0.8rem;
    
                    span {
                        font-weight: bold;
                    }
                }
                
                .view-n-limit{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 1rem;

                    .limit-input-container{

                        .limit-input{
                            @include inputStyles();
                            width: 90%;
                        }

                        .limit-error-message{
                            font-size: 0.6rem;
                            color: red;
                            margin-left: 0.2rem;
                            margin-top: 0.1rem;
                        }
                    }

                    Button{
                        color: #fff;
                        font-size: 0.8rem;
                        background-color: $horizon-turquoise;
    
                        &:disabled{
                            color: #e0dddd;
                            background-color: #98c0ba;
                        }
                    }
                }
    
            }

        }
    }

    .refresh-container{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-top: 1rem;
        margin-bottom: 0.5rem;

        .refresh-btn{
            color: $horizon-dark-slate;
            text-transform: capitalize;

            &:active{
                background-color: transparent;
            }
        }

        .last-refresh-text{
            font-size: 0.8rem;
        }
    }

    .query-builder-container{
        margin-top: 1rem;
        // overflow: auto;
        // max-height: 45vh;
    }

    .auto-refresh-container{
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .auto-refresh-header{
            color: $horizon-dark-slate;
        }
    }

    .actions {
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
        margin-top: 1rem;
        
        .close{
            @include cancelButtonStyles()
        }

        .save{
            @include saveButtonStyles()
        }
    }
}

@media screen and (max-width: 73rem) {
    .audience-builder-container{
    
        .audience-info-container{

            .audience-info{
                width: 60%;
            }

            .audience-contacts{
                width: 35%;

                .wrapper{
                    align-items: center;
                    
                    .view-n-limit{
                        flex-direction: column;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 45rem) {
    .audience-builder-container{
    
        .audience-info-container{
            flex-direction: column;

            .audience-info{
                width: 90%;
            }

            .audience-contacts{
                width: 90%;

                .wrapper{
                    align-items: center;
                    
                    .view-n-limit{
                        flex-direction: column;
                    }
                }
            }
        }
    }
}