@import "../../../../common/styles/styles";

.popover-menu {
  position: absolute;
  min-width: 20rem;
  z-index: 50;
  top: 2rem;
  left: -7rem;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;

  .arrow {
    position: absolute;
    width: 10px;
    transform: rotate(45deg);
    background-color: $horizon-grey-6;
    border-left: $horizon-dark-border;
    border-top: $horizon-dark-border;
    content: "";
    height: 0.5rem;
    left: 45%;
    z-index: 9;
  }

  .popover-body {
    overflow: auto;
    border: 1px solid #ddd;
    z-index: 1;
    margin-top: 0.2rem;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 2px 7px grey;
    padding: 0.25rem;
    background-color: $horizon-grey-6;

    .header-container {
      .header {
        @include headerStyles();
        font-size: 0.9rem;
        text-align: center;
        text-transform: uppercase;
      }
    }

    .form-container {
      margin-top: 0.5rem;

      .form-control {
        display: flex;
        gap: 0.5rem;
        margin: 0.5rem 0;
        align-items: center;
        justify-content: center;

        label {
          font-size: 0.8rem;
          text-transform: capitalize;
        }

        input {
          color: $horizon-dark-blue;
          border: none;
          outline: 0;
          padding: 0.2rem 0.5rem;
          background-color: transparent;
          border-bottom: $horizon-dark-border;
          font-style: italic;
        }
      }
    }
  }
}
