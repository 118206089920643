
@mixin keyStyles {
    width: 50%;
    display: inline-block;
    word-break: break-word;
}

.container{
    font-size: 0.8rem;
    
    .key{
        @include keyStyles()
    }

    .record-key {
        @include keyStyles();
        font-weight: bold;
    }
}