.details-container{

    .details-wrapper{

        .header{
            margin-bottom: 1rem;
        }

        .individual-info-section{
            display: flex;
            margin-bottom: 1rem;
            gap: 0.5rem;

            .section1{
                width: 40%;
            }

            .section2{
                display: flex;
                gap: 2rem;
                width: 60%;

                .metrics-container{
                    white-space: nowrap;

                    div{
                        text-align: right;
                        margin-top: 0.4rem;
                    }
                }

                .cards-container{
                    display: flex;
                    flex-wrap: wrap;
                }
            }
        }
    }
}


@media screen and (max-width: 38rem){
    .details-container{

        .details-wrapper{
            
            .individual-info-section{
                flex-direction: column;

                .section2{
                    flex-direction: column;
                }
            }
        }
    }
}