@import "../../../../common/styles/styles";

.input-btn-group {
  display: flex;
  gap: 0.5rem;

  Button {
    width: fit-content;
    padding: 0 0.4rem;
    min-width: 0px;
    color: #fff;
    box-shadow: $action-btn-box-shadow;
    background-color: $horizon-turquoise;

    &:disabled {
      cursor: default;
      color: #e0dddd;
      background-color: #98c0ba;
    }

    span {
      font-size: 1rem;
    }
  }

  .input {
    @include inputStyles();
    width: 100%;
  }
}
