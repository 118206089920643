@import '../../../../../common/styles/styles';


    .field-search-btn {
        margin-left: -0.5rem;
    }   

    .field-search-btn:hover {
        cursor: pointer;
    }
    
    .field-group-text{
        display: flex;
        flex-direction: column;
        font-size: 0.8rem;

        p{
            white-space: nowrap;
        }
    }

    .field-select{
        @include selectInputStyles-2()
    }

    .operator-select{
        @include selectInputStyles-2()
    }

    .input{
        @include selectInputStyles-2()
    }
