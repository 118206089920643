@import '../../../common/styles/styles';

.container{
    margin-top: 0.5rem;
    padding: 0.5rem;
    background-color: #eee;

    .error {
        color: #af0404;
        font-size: 0.8rem;
    }

    .message {
        font-size: 0.7rem;
        font-weight: bold;
        color: $horizon-dark-blue;
    }
}