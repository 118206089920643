
@import '../../../../../../common/styles/styles';

.container{
    border: 1px solid #bbb;
    min-width: 15rem;
    margin: 1rem 2rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    overflow: auto;
    font-size: 0.8rem;

    .expand-less-icon{
        float: left;
        margin-top: 0.5rem;
        margin-left: 0.25rem;
        cursor: pointer;
    }

    .orderDetailsTable {
        margin: 1rem 1.5rem;
    }

    .expand-more-icon{
        float: left;
        margin: 0.25rem 1rem 1rem 0.5rem;
        cursor: pointer;
    }

    .overviewContainer{
        display: flex;
        flex-wrap: wrap;

        .info{
            width: 13rem;
            white-space: nowrap;
        }
    }

    .tabs-container{
        @include reactTabsStyles()
    }
}