@import '../../../common/styles/styles';

.menu-item {
    cursor: pointer;
    padding: 0.5rem 0rem;
    text-align: center;
    color: #fff;

    &:hover{
        background-color: grey;
    }

    .icon {
        color: $horizon-grey;
    }

    .toggle-icon-container{
        width: 50%;
        margin: 0 auto;
        
        .toggle-btn{
            padding: 0;
            min-width: 0;
            color: $horizon-grey-6;
        }
    }
}