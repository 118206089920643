@import "../../../../common/styles/styles";

.container {
  font-size: 0.7rem;
  border: 2px solid $horizon-grey;
  border-radius: 0.5rem;

  .item {
    margin-left: 0.2rem;

    span {
      background-color: grey;
      padding: 0.01rem;
      border: 1px solid $horizon-grey;
      border-radius: 0.5rem;
      color: #fff;
      font-size: 0.8rem;
      margin-left: 0.2rem;
      cursor: pointer;
    }
  }
}
