@import "../../../../common/styles/styles";

.loader {
    margin: auto;
    border: 8px solid #f3f3f3;
    border-top: 8px solid $horizon-dark-slate;
    border-radius: 50%;
    width: 60px;
    max-width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}