

.container{

    .item{
        display: flex;
        margin-bottom: 0.3rem;

        .name{
            width: 50%;
        }

        .value{
            width: 50%;
        }
    }
}