@import "../../common/styles/styles";

.side-nav {
  position: relative;
  
  .side-nav-container{
    background-color: #343D49;
    width: 22rem;
    min-width: 22rem;
    height: 100%;

    .logo-container {
      background-color: #343D49;
      text-align: center;
  
      hr {
        @include lineStyles();
      }
  
      .logo {
        vertical-align: middle;
        cursor: pointer;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    }
  
    .menu-items-container{

      .action-container{
        float: right;
        margin-top: 1rem;
        
        .collapse-btn{
          padding: 0.5rem 0;
          min-width: 0;
          background-color: #343D49;
          color: #fff
        }
      }
  
      .logout-container {
        min-width: 17.5rem;
        position: absolute;
        bottom: 0;
    
        hr {
          @include lineStyles();
        }
      }
    }
  }


  
}


@media screen and (max-height: 25rem){

  .menu-items-container{
    display: flex;
    flex-direction: column;
    gap: 5rem;

    .logout-container {
      position: static!important;
  
      hr {
        @include lineStyles();
      }
  
    }
  }
}
