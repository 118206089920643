@import '../../../common/styles/styles';
@import '../adhocCommunicationModal';

.modal-container{

    .header{
        @include headerStyles()
    }

    .main{
        height: fit-content;
        position: relative;
        left: -0.9rem;
        width: 101.6%;
        margin-top: 1rem;
        padding: 0.5rem;
        background-color: $horizon-grey-6;

        .wrapper{
            display: flex;
            gap: 1rem;
            margin-bottom: 1rem;
        }

        .dt{
            width: 30%;
            min-width: 6rem;
            text-align: right;
        }

        .dd{
            width: 70%;
            min-width: 10rem;
            word-break: break-word;
        }
    }

    .actions {
        @include actionButtonStyles()
    }
}


@media screen and (max-width: 102rem){

    .modal-container{ 

        .main{
            width: 102.1%;
        }
    }
}