@import '../../../../common/styles/styles';

.tabs-container{
    @include reactTabsStyles();

    .tab-panel-container{
        margin-top: 1rem;

        &.individal-tap-panel{
            display: flex;

            div{
                width: 100%;

                hr{
                    margin: 0.5rem 0;
                }
            }
        }

        h5{
            font-weight: 200;
            font-size: 1.2rem;
            margin-top: 0.5rem;
        }
    }
}


@media screen and (max-width: 68rem){
    .tabs-container{
    
        .tab-panel-container{
    
            &.individal-tap-panel{
                display: flex;
                flex-direction: column;
    
                div{
                    width: 100%;
                }
            }
        }
    }
}


