@import '../../../common/styles/styles';

.btn-container{
    margin: 0.5rem 0rem;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.table-container{
    width: 100%;
    overflow-x: auto;
    padding-bottom: 3rem;

    .selection-table-container{
        width: fit-content;
        margin: 0 auto;
        
        .table {
            max-width: 100%;
            font-family: $hoizon-font-family;
            font-size: 0.875rem;
            border: 1px solid #ddd;
            border-radius: 0.5rem;
            border-spacing: 0px;
        
            .thead .tr {

                .th {
                    cursor: pointer;
                    padding: 0 2rem;
                    padding-bottom: 0.8rem;
                    padding-top: 0.3rem;
                    color: $horizon-dark-blue;
                    text-align: center;
                    white-space: nowrap;

                    span{
                        display: inline;
                        position: relative;
                        top: 0.5rem;
                    }

                }
            }
        }

    }
}
.pagination-container{
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    margin-top: 0.5rem;
    align-items: center;
    font-size: 0.75rem;
    font-weight: 400;
    font-family: $hoizon-font-family;

    .select-container{

        .select{
            font-size: 0.75rem;
            border: none;
        }
    }


    .button-container{

        button{
            margin: 0 0.2rem;
            padding: 0 0.4rem;
            border: none;
            cursor: pointer;
            color: $horizon-dark-blue;
            background-color: transparent;

            span{
                font-size: 1.4rem;
            }

            &:disabled{
                cursor: default;
                color: #e0dddd;
            }
        }
    }
}


.editableInput {
    @include inputStyles();
    width: 80%;
}

.product-code-error{
    font-size: 0.8rem;
    color: #f53c3c;
}