.urlAddons {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.formattedurl-textarea {
  height: 4rem;
  border-radius: 0.25rem;
  padding-left: 0.5rem;
  width: 100%;
}
.long-textarea {
  height: 2rem;
  border-radius: 0.25rem;
  padding-left: 0.5rem;
  padding-bottom: 0.2rem;
  width: 80%;
}
.utm-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  resize: none;
  border-radius: 0.25rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  padding-left: 0.5rem;
  padding-bottom: 0.5rem;
}
.utm-item {
  width: 15%;
  resize: none;
  border: 1px solid #0000003b;
  border-radius: 0.25rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  padding-left: 0.5rem;
}
.utm-item-campaign-name {
  width: 30%;
  resize: none;
  border: 1px solid #0000003b;
  border-radius: 0.25rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  padding-left: 0.5rem;
}
.utm-item-header {
  resize: none;
  border-radius: 0.25rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  padding-left: 0.5rem;
  /* padding-right: 2rem;
    width: 10%; */
  padding-right: 2rem;
  width: 3rem;
}

.fieldSelector_select-item__q9ACx Button {
  background-color: unset !important;
  box-shadow: unset !important;
  color: #757575;
  padding: unset !important;
}

.fieldSelector_select-item__q9ACx Button:disabled {
  cursor: default;
  color: #e0dddd !important;
}

#add-button {
  background-color: #83c1b9 !important;
  width: fit-content;
  padding: 0 0.4rem !important;
  min-width: 0px;
  color: #fff;
  box-shadow:
    0px 3px 1px -2px #00000033,
    0px 2px 2px #00000024,
    0px 1px 5px #0000001f !important;
}

#add-button:disabled {
  cursor: default;
  color: #e0dddd;
  background-color: #98c0ba;
}
