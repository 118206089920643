@import '../../../../common/styles/styles';

.item-container{
    display: flex;
    min-width: 100%;
    padding: 0.5rem 0.5rem;
    margin-bottom: 1rem;
    cursor: pointer;
    gap: 1rem;
    align-items: center;
    background-color: #fff;
    border: none;
    outline: 2px solid $horizon-turquoise;
    border-radius: 0.25rem;
    font-family: $hoizon-font-family;
    color: $horizon-dark-slate;

    &:hover{
        outline: 2px solid #5d8882;     
    }

    &:focus{
        outline: 2px solid $horizon-dark-blue;
    }

    &:disabled{
        background-color: #E0E0E0;
        outline: none;
        cursor: default;
    }

    .icon-container{
        padding: 1rem;  

        span {
            font-size: 3.5rem;
        }
    }

    .text-container{
        text-align: left;
        margin: 1rem;
        font-size: 0.875rem;
        
        .text-header{
            font-weight: 700;
            letter-spacing: 0.17px;
            margin-bottom: 1rem;
        }
    }
}