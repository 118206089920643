@import '../../../common/styles/styles';
@import '../adhocCommunicationModal';

.errored {
    border: 1px solid red;
}

.modal-container{

    .header{
        @include headerStyles()
    }

    .sub-header{
        @include subHeaderStyles()
    }

    .main{
        height: fit-content;
        position: relative;
        left: -0.9rem;
        width: 101.4%;
        margin-top: 1rem;
        padding: 0.5rem;
        background-color: $horizon-grey-6;

        .audience-selection-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;

            .select{
                @include selectInputStyles();
                width: 100%;
            }

            .email-input{
                @include inputStyles();
                padding: 0.4rem 0rem;
                padding-left: 0.5rem;
                width: 98.6%;
            }
        }
        
        .fields-container{
            display: flex;
            justify-content: center;
            margin: 1rem 0;
            gap: 1rem;

            .text{
                @include subHeaderStyles()
            }

            .fields{
                display: flex;
                gap: 0.5rem;

                .field-actions{
                    display: flex;
                    flex-direction: column;
                    gap: 0.25rem;
                    margin: 1rem 0;

                    Button {
                        min-width: 0;
                        padding: 0 1rem;
                        font-size: 0.8rem;
                        color: $horizon-turquoise;
                        border: 0.5px solid $horizon-turquoise;
                        background-color: #fff;
                
                        &:disabled{
                            cursor: default;
                            border: $horizon-dark-border;
                            color: $horizon-grey;
                        }
                    }
                }
            }
        }

        .test-n-shedule{
            margin-top: 1rem;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            .input-n-button{
                display: flex;
                gap: 0.5rem;

                input{
                    @include inputStyles();
                    width: 90%;

                    &.date-time-input{
                        width: 98%;
                    }
                }

                Button{
                    color: #fff;
                    font-size: 0.8rem;
                    background-color: $horizon-turquoise;
                    box-shadow: $action-btn-box-shadow;

                    &:disabled{
                        color: #e0dddd;
                        background-color: #98c0ba;
                    }
                }
            }
        }

        .actions {
            @include actionButtonStyles()
        }
    }
}



@media screen and (max-width: 102rem){

    .modal-container{ 

        .main{
            width: 102.1%;
        }
    }
}

@media screen and (max-width: 50rem){

    .modal-container{ 

        .main {
            
            .fields-container{
                flex-direction: column;
            }
        }
    }
}