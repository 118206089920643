@import '../../../../../common/styles/styles';

.multi-select-container{

    .button{
        color: #fff;
        font-size: 0.8rem;
        padding: 0.25rem 0.5rem;
        border-radius: 0.25rem;
        text-transform: capitalize;
        background-color: $horizon-turquoise;

        &:disabled{
            color: #e0dddd;
            background-color: #98c0ba;
        }

        &:hover{
            background-color: $horizon-turquoise;
        }
    }
}