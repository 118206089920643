@import '../../../common/styles/styles';

.container{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.7rem 0rem;
    color: #fff;
    
    .icon {
        padding-right: 2rem;
        color: $horizon-grey;
    }
    
    .text {
        width: 9.5rem;
        font-family: $hoizon-font-family;
        font-size: 1rem;
    }
}