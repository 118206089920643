@import '../../../styles/styles';

.popover-menu{
    position: absolute;
    min-width: 160px;
    z-index: 10;
    top: 0;
    right: 2rem;

    
    .menu-body {
        overflow: auto;
        border: 1px solid #ddd;
        z-index: 1;
        margin-top: 0.2rem;
        display: flex;
        flex-direction: column;
        border-radius: 0.25rem;
        box-shadow: 2px 2px 7px grey;
        background-color: $horizon-dark-slate;

        .menu-items{
            display: flex;
            flex-direction: column;
            // margin: 0.5rem 0;

            .menu-item{
                background-color: inherit;
                cursor: pointer;
                border: none;
                text-align: left;
                padding: 0.5rem 0.25rem;
                color: #fff;
                border-bottom: 1px solid #fff;

                &:hover{
                    background-color: $horizon-grey;
                }

                &:active{
                    background-color: #9c9a9a;
                }
                
                &:focus{
                    background-color: #9c9a9a   ;
                }

                &::after{
                    content: '...';
                }
            }
        }

    
    }
}