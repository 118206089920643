.container {
  width: 100%;
  height: 100%;

  .list-item-container{
    background-color: #fff;
    width: 10rem;
    height: 9rem;
    overflow: auto;
    padding: 0.5rem 1rem;
  }
}