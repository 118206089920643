
@import '../../../../common/styles/styles';

.container{
  display: flex;
  gap: 0.5rem;

  .auto-refresh-text{
    font-weight: 600;
    color: $horizon-dark-slate;
  }

  .input-container{
    display: flex;
    
    .radio-btn-label{
      cursor: pointer;
      margin-right: 0.25rem;
    }

    .radio-btn{
      cursor: pointer;
    }
  }
}