
.list-item-container{
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
    
    *{
        cursor: pointer;
    }
}