@import "../../../common/styles/styles";

.top-nav {
  width: 100%;
  background-color: #343D49;
  position: sticky;
  top: 0;
  z-index: 3;
  height: 3rem;
  min-width: fit-content;

  .input-n-user-icon{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    .input-right {
      display: flex;
      min-width: 30%;
      justify-content: space-between;
      align-items: center;
      margin-left: auto;
      margin-right: 1rem;
      padding: 0rem;
  
      .search-icon-container {
        background-color: #f3f4f6;
        width: 2.5rem;
        height: 1.875rem;
        border-radius: 0.25rem 0 0 0.25rem;
        border: none;
        text-align: center;
  
        .search-icon {
          color: $horizon-light-action-active;
          font-size: 1.6rem;
          padding: 0.2rem;
          vertical-align: middle;
  
          &:active{
            font-size: 1.3rem;
          }
        }
  
        .search-icon:hover {
          cursor: pointer;
        }
      }
  
      .top-nav-search-input {
        background-color: #f3f4f6;
        width: 100%;
        min-width: 12rem;
        height: 1.875rem;
        border-radius: 0 0.25rem 0.25rem 0;
        font-size: 1rem;
        border: none;
        outline: none;
        
        &::placeholder{
          padding-left: 0.2rem;
        }
      }
  
      .search-criteria-icon-container {
        width: 2.5rem;
        margin-left: 0.2rem;
        height: 1.875rem;
        border-radius: 0.25rem 0 0 0.25rem;
        border: none;
        text-align: center;
  
        .search-criteria-icon {
          color: #fff;
          font-size: 1.6rem;
          vertical-align: middle;
        }
  
        .search-criteria-icon:hover {
          cursor: pointer;
        }
      }
  
      .modal-select {
        padding: 1rem, 0.8rem;
        cursor: pointer;
        font: inherit;
        white-space: nowrap;
        text-overflow: ellipsis;
        height: auto;
      }
    }
  
    .right {
      padding: 0 0.6rem;
  
      .user-icon {
        vertical-align: middle;
      }
    }
  }

}


@media screen and (max-width: 31rem){
  .top-nav {

    .input-n-user-icon{

      .center{
        width: 70%;
      }
    }
  }
}