@import '../../../../common/styles/styles';

.container{
    margin: 1rem;

    .form-container{
        display: flex;
        gap: 1rem;

        input{
            @include inputStyles();
            width: 90%;
            margin: 0.5rem 0rem;
        }

        .xcmid-error-text{
            font-size: 0.7rem;
            color: red;
            margin-top: -0.1rem;
        }
    }

    .actions{

        .search-btn{

            &:disabled{
                background-color: rgb(80, 78, 78)!important;
            }
        }
    }
}