@import '../../common/styles/styles';

.individual-container{

    .expand-btn-container{
        text-align: center;
        margin-top: -0.5rem;

        .expand-btn{
            padding: 0;
            background-color: $horizon-grey-6;
        }

        .search-message{
            font-size: 0.7rem;
            color: red;
            margin-top: 0.2rem;
        }
    }
}