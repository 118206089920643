@import '../../styles/styles';

.modal {
    background-color: #fff;
    width: 50%;
    margin: 0rem auto;
    min-height: 2rem;
    min-width: 15rem;
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: 0px 5px 5px -3px #00000033, 0px 8px 10px 1px #00000024, 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 1rem;

    .close-button {
        width: max-content;
        float: right;
        margin-top: -5px;
 
        button {
            font-size: 2rem;
            cursor: pointer;
            border: 0;
            background-color: transparent;
            color: $horizon-grey;
 
            &:hover {
                transition: background-color 0.5s;
                background-color: #eeeeee;
            }
 
            &:focus {
                background-color: #eeeeee;
                border-width: 1px;
            }
        }
    }
}