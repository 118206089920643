@import '../../../../../common/styles/styles';

.popover-container{
    position: absolute;
    width: 100%;
    z-index: 50;

    .menu-body {
      max-height: 15rem;
      overflow: auto;
      border: 1px solid #ddd;
      z-index: 1;
      margin-top: 0.2rem;
      padding-bottom: 0.2rem;
      display: flex;
      flex-direction: column;
      border-radius: 0.25rem;
      box-shadow: 2px 2px 7px grey;
      background-color: #ffffff;

      .search-input{
        @include inputStyles();
        width: 85%;
        margin: 0.5rem auto;
      }

      .select-text-container{
        margin-left: 0.5rem;

        p{
          font-size: 0.8rem;
          color: royalblue;
          cursor: pointer;

          &:hover{
            text-decoration: underline;
          }
        }
      }
      
      .ul{
        list-style-type: none;
        margin: 0.5rem auto;
        width: 90%;

        .li{
          cursor: pointer;
          display: flex;
          align-items: center;
          font-size: small;
          gap: 0.5rem;
          
          label{
            cursor: pointer;
          }
        }
      }

      .text{
        font-size: 0.8rem;
        margin: 0.5rem auto;
      }
    }
}