@import '../../../../common/styles/styles';

.delete-container {

    .header-container{

        .header{
            @include headerStyles()
        }
    }

    .text{
        margin-top: 0.5rem;
        font-size: 0.9rem;

        strong{
            font-style: italic;
        }
    }

    .actions {
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
        margin-top: 1rem;
        
        .no{
            color: $horizon-dark-blue;
            font-size: 0.8rem;
            background-color: #E0E0E0;
            padding: 0.25rem 0.75rem;
            box-shadow: $action-btn-box-shadow;
        }

        .confirm{
            color: #fff;
            font-size: 0.8rem;
            padding: 0.25rem 0.75rem;
            background-color: #f14b5b;
            box-shadow: $action-btn-box-shadow;

            i{
                // display: flex;
                // align-items: center;
                // justify-content: center;
            }

            .delete-icon{
                font-size: 1.2rem;
            }

            &:disabled{
                color: #e0dddd;
                background-color: #da979e;
            }
        }
    }
}