@import '../../../../common/styles/styles';

.container{
  position: relative;
  width: 100%;
  
  .select-container{
    
    .select{
      @include selectInputStyles-2();
      width: 100%;
      font-size: 0.9rem;
    }
  }
}