@import '../../../../../common/styles/styles';

@mixin buttonStyles {
    color: #fff;
    font-size: 0.8rem;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    text-transform: capitalize;
    background-color: $horizon-turquoise;

    &:disabled{
        color: #e0dddd;
        background-color: #98c0ba;
    }

    &:hover{
        background-color: $horizon-turquoise;
    }

}

.group-container{
    border: 0.5rem solid #F3F4F6;
    padding: 0.5rem;
    margin-bottom: 0.5rem;

    .group-wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;

        .action-wrapper{
            display: flex;
            margin-bottom: 0.5rem;
            gap: 0.5rem;
            align-items: center;

            .combinator{
                @include selectInputStyles-2()
            }
        
            .rule-button{
                @include buttonStyles()
            }
            
            .group-button{
                @include buttonStyles()
            }
        }

    
        .remove-group-button{
            cursor: pointer;
            padding: 0.15rem;
            color: grey;
            border-radius: 0.25rem;
            border: none;
            background-color: transparent;

            span{
                font-size: 1.5rem;
                line-height: normal;
            }
    
            &:active{
                color: #dc3545;
            }
    
            &:hover{
                color: #dc3545;
            }
        }
    }

}

@media screen and (max-width: 44rem){
    .group-container {
        position: relative;
        padding: 0.25rem;
        border: 0.25rem solid #F3F4F6;
        flex-direction: column;

        .group-wrapper{
            flex-direction: column;

            .action-wrapper{
                flex-direction: column;
            }
        }
    }
}