@import '../../../common/styles/styles';

.connections-container{
    .header-container{
        display: flex;
        margin-top: 3rem;
        margin-left: 3rem;;

        .header-image {
            font-size: 3.5rem;
            font-style: normal;
            top: 1rem;
            font-weight: 200;
        }

        .header-text {
            font-size: 2.5rem;
            font-weight: 400;
            margin-right: 1rem;
        }
    }
}