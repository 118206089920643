.container {
    display: flex;
    margin-left: 1rem;
    margin-top: 0.5rem;

    .leftSide {
        width: 30%;
    }
    
    .rightSide {
        width: 70%;
        padding: 1rem,
    }
}