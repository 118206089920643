@import '../../../../common/styles/styles';

.container {
    float: right;
    padding: 0.7rem 1rem;
    
    .image-text-container{
        cursor: pointer;
        display: flex;
        align-items: center;
        color: #fff;
        width: 100%;
        
        .icon {
            padding-right: 1rem;
            color: $horizon-grey;
        }
        
        .text {
            font-family: $hoizon-font-family;
            font-size: 1rem;
        }
    }
}