@import '../../common/styles/styles';


.side-nav-mobile{
    width: 4rem;
    min-width: 4rem;
    background-color: $horizon-dark-slate;

    .logo-container{
        cursor: pointer;
        text-align: center;
        height: 3rem;
        background-color: $horizon-dark-blue;
    
        .logo {
          width: 2rem;
          padding-top: 0.5rem;
        }
    }

    hr{
        @include lineStyles()
    }

    .menu-items-div{

        .menu-items-container{
    
            .top-items{

                hr{
                    @include lineStyles();
                }
            }
    
            .logout-container {
                min-width: 4rem;
                position: absolute;
                bottom: 0;
            
                hr {
                    @include lineStyles();
                }
            }
        }
    }
    .action-container{
        margin-top: 1rem;
        float: right;

        .expand-btn{
            padding: 0.5rem 0;
            min-width: 0;
            background-color: $horizon-dark-slate;
            color: #fff
        }
    }
}