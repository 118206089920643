
.table {
  width: 100%;
  .tbody {
    
    .tr{
      width: 70%;
      display: flex;
      justify-content: space-between;
      font-size: 0.8rem;
      
      td{
        white-space: nowrap;
        
        &:nth-child(even){
          font-weight: 600;
        }
      }
    }
  }
}
