.nav-container {
    display: flex;
    vertical-align: middle;
    margin-bottom: 0.5rem;

    .nav-btn {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        background-color: #343D49;
        border: 1px solid #fff;
        color: #fff;
        width: auto;
        text-align: center;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
    
    .nav-btn:hover {
        cursor: pointer;
        background-color: #232C38;
    }
}