@import '../../common/styles/styles';

@mixin actionButtonStyles {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1rem;
    
    .cancel{
        @include cancelButtonStyles()
    }

    .next{
        @include saveButtonStyles()
    }
}

