@import "../../../../../common/styles/styles";


@mixin textStyles {
  white-space: nowrap;
  border-radius: 0.5rem;
  padding: 0.1rem 0.25rem;
  width: 5rem;
  margin: 0 auto;
}

.td {
  border-top: 1px solid #ddd;
  padding: 8px;
  text-align: center;

  .btn-container {
    display: flex;
    
    .approve-btn {
      color: #fff;
      background-color: green;
      font-size: 0.8rem;
      margin: 0.25rem;
    }

    .decline-btn {
      color: #fff;
      background-color: red;
      font-size: 0.8rem;
      margin: 0.25rem;
    }
  }

  .pending-text {
    @include textStyles();
    border: 1px solid grey;
    width: fit-content;
  }

  .sending-scheduled-text {
    @include textStyles();
    border: 1px solid darkgreen;
    color: green;
  }

  .declined-text {
    @include textStyles();
    border: 1px solid red;
    background-color: red;
    color: #fff;
  }

  .sent-text {
    @include textStyles();
    border: 1px solid darkgreen;
    background-color: green;
    color: #fff;
  }
}
